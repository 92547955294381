import React from "react";
import {HeadFC} from "gatsby";
import {Header} from "../components/header";
import {Footer} from "../components/footer";
import {useParosSkeletonStyle} from "../hooks/use_paros_skeleton";
import {SEO} from "../components/seo";
import {StaticImage} from "gatsby-plugin-image";

const ParosPage = () => {
  return (
    <>
      <Header/>
      <main className="h-full">
        <section className="h-full sm:h-1/5 max-w-6xl mx-auto pt-10 pb-14" style={useParosSkeletonStyle()}>
          <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
            <h1 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 font-poppins">
              Discover Paros
            </h1>
          </div>
        </section>
        <section className="w-full bg-white py-20 px-8">
          <div className="max-w-6xl mx-auto">
            <h2 className="text-3xl font-poppins text-gradient">
              The Ultimate Guide to Paros Island
            </h2>
            <p className="mt-2">
              Are you dreaming of the ultimate Greek island experience? Look no further than Paros Island,
              located in the heart of the Cyclades in the Aegean Sea.
              <br/>Paros offers a perfect blend of stunning natural beauty,
              rich cultural heritage, delicious food, and vibrant nightlife. In this guide,
              we will take you through everything you need to know to plan your perfect Paros vacation.
            </p>
            <br/>
            <h3 className="text-xl font-poppins">
              How to visit Paros
            </h3>
            <p className="mt-2">
              Paros Island is easily accessible by air or sea. The island has its own airport,
              with direct flights from Athens and Thessaloniki during the summer months.
              Alternatively, you can take a ferry from Piraeus which takes approximately 4-5 hours.
              If you are planning to visit multiple islands, you can also take the ferry from nearby
              islands such as Mykonos, Santorini, Naxos and Sifnos.
            </p>
            <br/>
            <h3 className="text-xl font-poppins">
              Where to stay in Paros
            </h3>
            <p className="mt-2">
              Paros Island offers a wide range of accommodation options to suit all budgets and preferences.
              If you're looking for a luxurious experience, stay in one of the many high-end resorts and villas located
              around the island. Alternatively, there are plenty of budget-friendly options, including guesthouses, and
              apartments.
            </p>
            <br/>
            <h3 className="text-xl font-poppins">
              Exploring the Island
            </h3>
            <p className="mt-2">
              If you're planning to explore Paros Island on your own terms, renting a car is a great way to do so. With
              our extensive fleet of cars, including both economy and luxury models, we offer competitive rates and
              top-notch customer service. Renting a car with <a href="/"><span
              className="text-gradient font-bold">COOL</span>CAR <span className="text-gray-500">RENTALS</span></a> will
              give you the flexibility to visit all
              the top attractions on your own schedule, and we're happy to provide recommendations for the best routes
              and hidden gems to discover during your stay. Contact us to book your car rental and start your Paros
              Island adventure.
            </p>
            <br/>
            <h3 className="text-xl font-poppins">
              Top Attractions in Paros Island
            </h3>
            <p className="mt-2">
              Paros Island is known for its beautiful beaches, charming villages, and rich history.
              Some of the top attractions to visit during your stay include:
            </p>
            <ul className="list-disc ml-8 mt-2">
              <li className="mb-2 mt-4">
                <a href="https://goo.gl/maps/eYftk1K9GYFhQiBU8" rel="nofollow noopener" target="_blank"><h4
                  className="text-base font-bold">Naousa Village</h4></a>
                Naousa is a charming fishing village located on the northern coast of Paros Island in Greece. This
                picturesque town is known for its colorful boats, whitewashed buildings, and narrow cobblestone streets.
                Naousa is a popular spot for tourists and locals alike, thanks to its beautiful beaches, crystal-clear
                waters, and lively nightlife. Naousa is also home to several
                restaurants, cafes, and bars, making it the perfect spot for a night out. Beyond the town, visitors can
                explore the nearby beaches and coves, which offer breathtaking views of the Aegean Sea. With its authentic
                atmosphere, stunning scenery, and lively nightlife, Naousa is a must-visit destination for anyone
                traveling to Paros.
              </li>
              <li className="mb-2">
                <a href="https://goo.gl/maps/UihhriLchyRvYWez7" rel="nofollow noopener" target="_blank"><h4
                  className="text-base font-bold">Parikia Town</h4></a>
                Parikia is the island's capital. This bustling town is
                the cultural and commercial center of the island and offers a wide range of attractions for visitors.
                Parikia is known for its charming narrow streets, whitewashed buildings, and colorful flowers. Visitors
                can explore the town's many landmarks, including the impressive 13th-century Venetian fortress, the
                Ekatontapyliani church, and the Archaeological Museum of Paros. The town also boasts a bustling port,
                where visitors can take a ferry to nearby islands or enjoy a sunset cruise. Parikia is also home to
                several shops, cafes, and restaurants, making it a popular spot for dining and shopping.
              </li>
              <li className="mb-2">
                <a href="https://goo.gl/maps/boNBqSXLJWKFvqTt6" rel="nofollow noopener" target="_blank"><h4
                  className="text-base font-bold">Kolymbithres Beach</h4></a>
                This beautiful beach is famous for its crystal-clear turquoise waters and uniquely sculpted rock
                formations.
                The beach is divided into several smaller coves, each offering its own unique beauty and character.
                Visitors can enjoy swimming, sunbathing, and exploring the surrounding landscape. The beach is
                surrounded by lush greenery and towering cliffs, providing a serene and peaceful atmosphere. With its
                breathtaking scenery and tranquil surroundings, Kolymbithres Beach is a must-visit destination for
                anyone traveling to Paros.
              </li>
              <li className="mb-2">
                <a href="https://goo.gl/maps/KyMTk2Kpnpkoor8c6" rel="nofollow noopener" target="_blank"><h4
                  className="text-base font-bold">Golden Beach</h4></a>
                Golden Beach, also known as Chrissi Akti, is a spectacular sandy beach located on the southeastern coast
                of Paros Island in Greece. The beach is popular for its crystal-clear waters and wide expanse of golden
                sand. Golden Beach is also known for being one of the best spots in Paros for windsurfing, thanks to the
                frequent strong winds that blow through the area. The beach is lined with sun loungers, umbrellas, and
                beach bars, making it the perfect spot to relax and soak up the sun.
              </li>
              <li>
                <a href="https://goo.gl/maps/hx515xatvW9syYT4A" rel="nofollow noopener" target="_blank">
                  <h4
                    className="text-base font-bold mb-1">Lefkes Village</h4>
                </a>
                Lefkes is a charming and picturesque village located in the heart of Paros Island in Greece. Lefkes was
                once the capital of Paros and still maintains a strong connection to the
                island's history and culture. Visitors can explore the village's many churches, including the
                15th-century Agia Triada church, which features beautiful Byzantine architecture. Lefkes also offers
                stunning views of the surrounding hills and valleys, making it a popular spot for hikers and nature
                lovers. The village is home to several quaint cafes and restaurants where visitors can enjoy traditional
                Greek cuisine. With its authentic atmosphere and rich history, Lefkes is a must-visit destination for
                anyone traveling to Paros.
              </li>
            </ul>
            <div className="flex flex-wrap justify-between">
              <StaticImage className="my-8 rounded-2xl w-full md:w-[49%]" src="../images/paros_naousa.jpg"
                           alt="Paros island Naousa at noon"/>
              <StaticImage className="my-8 rounded-2xl w-full md:w-[49%]" src="../images/paros_beach.webp"
                           alt="Paros island Kolymbithres beach"/>
            </div>
            <br/>
            <h3 className="text-xl font-poppins">
              Food and Drink in Paros Island
            </h3>
            <p className="mt-2">
              Paros Island is known for its delicious food, including fresh seafood, locally produced cheese, and
              traditional Greek dishes. Some of the must-try dishes include:
            </p>
            <ul className="list-disc ml-8 mt-2">
              <li>
                <b>Octopus:</b> Grilled or fried, octopus is a local delicacy that you won't want to miss.
              </li>
              <li>
                <b>Tzatziki:</b> This classic Greek dip is made with yogurt, cucumber, garlic, and dill and is the
                perfect
                accompaniment to grilled meat or bread.
              </li>
              <li>
                <b>Souvlaki:</b> This popular street food consists of grilled meat (usually pork or chicken) served on a
                skewer
                with pita bread and toppings.
              </li>
              <li>
                <b>Wine:</b> Paros Island is known for its excellent wine, with several local vineyards producing
                high-quality
                varieties.
              </li>
            </ul>
            <div className="flex flex-wrap justify-between">
              <StaticImage className="my-8 rounded-2xl w-full md:w-[49%]" src="../images/paros_food.jpg"
                           alt="A nice tavern of Paros Naousa"/>
              <StaticImage className="my-8 rounded-2xl w-full md:w-[49%]" src="../images/paros_food_2.jpg"
                           alt="A nice tavern in the island of Paros"/>
            </div>
            <br/>
            <h3 className="text-xl font-poppins">
              Nightlife in Paros Island
            </h3>
            <p className="mt-2">
              Paros Island is also known for its vibrant nightlife, with plenty of bars and clubs to choose from.
              Naoussa village is particularly famous for its nightlife, with several bars and clubs located around the
              harbor. Parikia also has several options, including beach bars and nightclubs.
            </p>
            <br/>
            <h3 className="text-xl font-poppins">
              Conclusion
            </h3>
            <p className="mt-2">
              Paros is the perfect destination for anyone looking for a mix of natural beauty, culture, and
              nightlife. With its stunning beaches, charming villages, delicious food, and vibrant nightlife, Paros has
              something to offer everyone. Whether you're looking for a luxurious getaway or a budget-friendly vacation,
              Paros Island is sure to leave you with unforgettable memories.
            </p>
            <br/>
            <p>
              For more suggestions feel free to contact us from email, or in person if we meet for your rental.
            </p>
          </div>
        </section>
      </main>
      <Footer/>
    </>
  )
}

export const Head: HeadFC = () => (
  <SEO
    title="Ultimate Guide to Paros Island | Coolcar Rentals"
    description="Everything you need to know about the ultimate Greek island experience in Paros. Explore stunning beaches, rich cultural heritage, and vibrant nightlife."
    image="https://coolcar.gr/paros_article_thumbnail.jpg"
  />
);

export default ParosPage;